import axios from "@/config/axios";

const initialState = () => {
  return {
    entityViewById: null,
    allEntityViews: null,
    deleteEntityViewStatus: false,
    createEntityView: null,
    updateEntityView: null,
    EntityViewRedirectRoute : null,
  };
};
const state = initialState();

const getters = {
  getEntityViewById: (state) => state.entityViewById,
  getAllEntityViews: (state) => state.allEntityViews,
  getDeleteEntityViewStatus: (state) => state.deleteEntityViewStatus,
  getCreateEntityView: (state) => state.createEntityView,
  getUpdateEntityView: (state) => state.updateEntityView,
  getEntityViewRedirectRoute :(state)=>state.EntityViewRedirectRoute,
};
const mutations = {
  setEntityViewById(state, data) {
    state.entityViewById = data;
  },
  setAllEntityViews(state, data) {
    state.allEntityViews = data;
  },
  setDeleteEntityViewStatus(state, status) {
    state.deleteEntityViewStatus = status
  },
  setCreateEntityView(state, data){
    state.createEntityView = data
  },
  setUpdateEntityView(state, data){
    state.updateEntityView = data
  },
  setEntityViewRedirectRoute(state,data){
    state.EntityViewRedirectRoute = data
  }

};
const actions = {
  createEntityViewById: async ({ commit }, params) => {
    try {
      commit("setCreateEntityView", null);
      const response = await axios.post(`entity-view-configuration`, params);
      commit("setCreateEntityView", response.data.data);
    } catch (err) {
      commit("setCreateEntityView", null);
    }
  },
  updateEntityViewById: async ({ commit }, params) => {
    try {
      commit("setUpdateEntityView", null);
      const response = await axios.patch(`entity-view-configuration/${params.id}`, params);
      commit("setUpdateEntityView", response.data.data);
    } catch (err) {
      commit("setUpdateEntityView", null);
    }
  },
  deleteEntityViewById: async ({ commit }, id) => {
    try {
      commit("setDeleteEntityViewStatus", null);
      await axios.delete(`entity-view-configuration/${id}`);
      commit("setDeleteEntityViewStatus", true);
    } catch (err) {
      commit("setDeleteEntityViewStatus", null);
    }
  },
  getEntityViewById: async ({ commit }, params) => {
    try {
      commit("setEntityViewById", null);
      const response = await axios.get(`entity-view-configuration/${params.id}`, {params});
      commit("setEntityViewById", response.data.data);
    } catch (err) {
      commit("setEntityViewById", null);
    }
  },
  getAllEntityViews: async ({ commit }, params) => {
    try {
      commit("setAllEntityViews", null);
      const response = await axios.get(`entity-view-configuration`, {
        params,
      });
      commit("setAllEntityViews", response.data);
    } catch (err) {
      commit("setAllEntityViews", null);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
